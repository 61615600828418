document.addEventListener('alpine:init', () => {
  Alpine.data('step', () => ({
    expanded: false,
    ready: false,
    button: null,

    init() {
      if (document.body.classList.contains('wp-admin')) {
        this.ready = true;
      } else {
        this.$nextTick(() => {
          let more = false;

          for (const child of [...this.$root.querySelector('[x-ref="innerBlocks"]').children]) {
            if (more) {
              child.setAttribute('x-show', 'expanded');
            }

            if (child.tagName === 'P' && child.textContent.match(/\[.+\]/)) {
              this.button = document.createElement('button');
              this.button.textContent = child.textContent.trim().slice(1, -1);
              this.button.classList.add(
                'relative',
                'font-semibold',
                'after:absolute',
                'after:inset-x-0',
                'after:bottom-0',
                'after:h-0.5',
                'after:bg-midnight',
                'hocus:after:-translate-y-0.5',
                'hocus:after:opacity-0',
                'after:transition'
              );
              this.button.addEventListener('click', () => {
                this.toggle();
              });
              child.replaceWith(this.button);
              more = true;
            }
          }

          this.ready = true;
        });
      }
    },

    toggle() {
      this.expanded = !this.expanded;

      if (this.expanded) {
        this.button.style.display = 'none';
      } else {
        this.button.style.display = null;
      }
    },
  }));
});