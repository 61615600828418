document.addEventListener('alpine:init', () => {
  Alpine.data('video', () => ({
    isOpen: false,
    isVisible: false,
    visibleTimeout: null,

    async open() {
      if (this.$refs.vimeo) {
        const match = this.$root.dataset.vimeo.match(/([0-9]+)(?:\/([0-9a-z]+)|$)?/i);

        if (match) {
          await new Promise((resolve) => {
            this.$refs.vimeo.addEventListener('next', () => resolve(), { once: true });
            this.$refs.vimeo.innerHTML = `<iframe @load="$dispatch('next')" src="https://player.vimeo.com/video/${
              match[1]
            }?${
              match[2] ? `h=${match[2]}&` : ''
            }&autoplay=1&title=0&byline=0&portrait=0" class="video-landscape:w-auto w-full video-landscape:h-full h-auto aspect-video m-auto rounded pointer-events-auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`;
          });
        }
      }

      this.isOpen = true;
      clearTimeout(this.visibleTimeout);

      setTimeout(() => {
        this.isVisible = true;
        this.$refs.video?.play();
      }, 50);
    },

    close() {
      this.isVisible = false;
      this.visibleTimeout = setTimeout(() => {
        this.isOpen = false;
        this.$refs.video?.pause();

        if (this.$refs.vimeo) {
          this.$refs.vimeo.innerHTML = '';
        }
      }, 300);
    },
  }));
});