document.addEventListener('alpine:init', () => {
  Alpine.data('anchorButtons', () => ({
    buttons: [],

    init() {
      document.body.querySelectorAll('[data-block][id]').forEach((block) => {
        if (block.id) {
          this.buttons.push({
            label: block.id.replaceAll('-', ' ').replaceAll('  ', '-'),
            href: `#${block.id}`,
          });
        }
      });
    },
  }));
});