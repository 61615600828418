document.addEventListener('alpine:init', () => {
  Alpine.data('articleFloater', () => ({
    stacked: false,
    stackContainer: document.querySelector('.blocks .prose[x-ref="innerBlocks"'),
    originalContainer: null,
    emailDirty: false,

    init() {
      if (this.stackContainer) {
        this.originalContainer = this.stackContainer.parentElement.parentElement.parentElement;
        this.originalContainer.append(this.$root);
        this.originalContainer.classList.add('relative');

        if (!document.body.classList.contains('single-glossary')) {
          this.originalContainer.style.paddingTop = 0;
        }

        this.originalContainer.prepend(this.originalContainer.parentElement.previousElementSibling);
        this.update = this.update.bind(this);
        this.update();
        window.addEventListener('resize', this.update);
      }
    },

    update() {
      if (window.innerWidth < 1439) {
        if (!this.stacked) {
          this.stackContainer.append(this.$root);
          this.stacked = true;
        }
      } else {
        if (this.stacked) {
          this.originalContainer.append(this.$root);
          this.stacked = false;
        }
      }
    },
  }));

  Alpine.data('newsletterFormFloater', () => ({
    disabled: false,
    formError: null,
    fieldErrors: {},
    successMessage: '',

    init() {
      if (GLOBALS.recaptchaSiteKey) {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${GLOBALS.recaptchaSiteKey}`;
        document.head.append(recaptchaScript);
        window.recaptchaLoaded = true;
      }
    },

    async subscribe(e) {
      const formData = new FormData(e.target);

      if (window.recaptchaLoaded && window.grecaptcha) {
        await new Promise((resolve) => {
          grecaptcha.ready(function () {
            grecaptcha.execute(GLOBALS.recaptchaSiteKey, { action: 'submit' }).then(function (token) {
              formData.set('recaptchaToken', token);
              resolve();
            });
          });
        });
      }

      this.disabled = true;
      this.formError = null;
      this.fieldErrors = {};

      fetch(GLOBALS.ajaxUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then(({ data, success }) => {
          if (success) {
            if (data.message) {
              this.successMessage = data.message;
            } else if (data.redirect) {
              window.location = data.redirect;
            } else {
              this.successMessage =
                'Vielen Dank für Ihre Formularübermittlung. Ihre Anfrage wurde erfolgreich gesendet!';
            }
          } else {
            this.formError = data.errors?.form ?? null;
            this.fieldErrors = data.errors?.fields ?? {};
            this.disabled = false;
          }
        })
        .catch((e) => {
          console.error(e);
          this.disabled = false;
        });
    },
  }));
});