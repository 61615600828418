document.addEventListener('alpine:init', () => {
  Alpine.data('form', () => ({
    formError: null,
    fieldErrors: {},
    disabled: false,
    successMessage: '',

    init() {
      this.$nextTick(() => {
        const search = new URLSearchParams(window.location.search);

        for (const [name, value] of search.entries()) {
          const input = this.$root.querySelector(`[name="${name.replace('_', '')}"]`);

          if (input) {
            input.value = value;
          }
        }
      });
    },

    submit(e) {
      const formData = new FormData(e.target);

      this.disabled = true;
      this.formError = null;
      this.fieldErrors = {};

      fetch(GLOBALS.ajaxUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then(({ data, success }) => {
          if (success) {
            if (data.message) {
              this.successMessage = data.message;
            } else {
              this.successMessage =
                'Vielen Dank für Ihre Formularübermittlung. Ihre Anfrage wurde erfolgreich gesendet!';
            }
          } else {
            this.formError = data.errors?.form ?? null;
            this.fieldErrors = data.errors?.fields ?? {};
            this.disabled = false;
          }
        })
        .catch((e) => {
          console.error(e);
          this.disabled = false;
        })
        .finally(() => {
          setTimeout(() => {
            const target = this.$root.querySelector('.success, .form-error, .error');

            if (target?.classList.contains('success')) {
              target.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth' });
            } else {
              target?.parentElement.scrollIntoView({ behavior: 'smooth' });
            }
          });
        });
    },
  }));
});