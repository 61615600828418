document.addEventListener('alpine:init', () => {
  Alpine.data('expandableBox', () => ({
    overflows: false,
    expanded: false,

    init() {
      this.$nextTick(() => {
        this.refresh();
      });

      window.addEventListener('resize', () => this.refresh());
    },

    refresh() {
      if (document.body.classList.contains('wp-admin')) {
        this.overflows = false;
        this.expanded = true;
      } else {
        this.overflows = this.$root.scrollHeight > this.$root.offsetHeight;
      }
    },
  }));
});