document.addEventListener('alpine:init', () => {
  Alpine.data('toggle', () => ({
    initialized: false,
    isOpen: false,
    resizeCounter: 1,
    timeout: null,

    init() {
      this.isOpen = !!+this.$root.dataset.open;
      this.initialized = document.readyState == 'complete';

      window.addEventListener('load', () => {
        this.initialized = true;
      });

      window.addEventListener('resize', () => {
        this.$refs.content.style.height = 0;
        this.$refs.content.style.transition = 'none';
        this.resizeCounter++;

        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.$refs.content.style.transition = null;
          this.timeout = null;
        }, 500);
      });
    },
  }));
});