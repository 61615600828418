document.addEventListener('alpine:init', () => {
  Alpine.data('sectionNews', () => ({
    init() {
      if (!document.body.classList.contains('wp-admin')) {
        this.$nextTick(() => this.refresh());
        window.addEventListener('resize', () => this.refresh());
      }
    },

    refresh() {
      this.$root.style.width = `${document.body.clientWidth}px`;
      this.$root.style.marginLeft = 0;
      const left = this.$root.offsetLeft;
      this.$root.style.marginLeft = `-${left}px`;
    },
  }));
});