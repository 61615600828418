document.addEventListener('alpine:init', () => {
  Alpine.data('iconBox', () => ({
    active: false,
    active2: false,

    open() {
      if (!document.body.classList.contains('wp-admin')) {
        this.active = true;
        setTimeout(() => {
          this.active2 = true;
        });
      }
    },

    close() {
      this.active2 = false;

      setTimeout(() => {
        this.active = false;
      }, 300);
    },
  }));
});