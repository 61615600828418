document.addEventListener('alpine:init', () => {
  Alpine.data('floater', () => ({
    scrolled: false,
    ready: false,
    initialized: false,

    init() {
      window.addEventListener('scroll', () => this.update(), { passive: true });
      this.update();
      this.initialized = true;
      setTimeout(() => (this.ready = true));
    },

    update() {
      this.scrolled = window.scrollY > 0;
    },
  }));
});