document.addEventListener('alpine:init', () => {
  Alpine.data('jobs', () => ({
    department: 'All',
    location: 'All',
    hasTopJobs: true,
    hasOtherJobs: true,
    fieldErrors: {},

    filter() {
      this.hasTopJobs = false;
      this.hasOtherJobs = false;

      this.$root.querySelectorAll('[data-list-item]').forEach((el) => {
        const visible =
          (this.department === 'All' || el.dataset.department === this.department) &&
          (this.location === 'All' || el.dataset.location === this.location);

        el.style.display = visible ? 'flex' : 'none';
        el.hidden = !visible;

        if (visible && el.dataset.topJob) {
          this.hasTopJobs = true;
        } else if (visible) {
          this.hasOtherJobs = true;
        }
      });

      this.$refs.showMore?.remove();
    },

    showAll() {
      this.$root.querySelectorAll('[data-list-item]').forEach((el) => {
        el.style.display = 'flex';
        el.hidden = false;
      });

      this.$refs.showMore?.remove();
    },
  }));
});