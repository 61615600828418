document.addEventListener('alpine:init', () => {
  Alpine.data('speakers', () => ({
    sliderEl: document.createElement('div'),

    init() {
      if (!window.wp) {
        this.sliderEl.classList.add('swiper-container', 'w-full', 'overflow-hidden');

        const wrapper = document.createElement('div');
        wrapper.classList.add('swiper-wrapper');

        const pagination = document.createElement('div');
        pagination.classList.add('swiper-pagination', 'relative', 'flex', 'justify-center', 'mt-6', 'gap-2');

        [...this.$root.children].forEach((child) => {
          const slide = document.createElement('div');
          slide.classList.add('swiper-slide');
          slide.append(child);
          wrapper.append(slide);
        });

        this.sliderEl.append(wrapper);
        this.sliderEl.append(pagination);
        this.$root.append(this.sliderEl);
        this.initSwiper();
      }
    },

    initSwiper() {
      new Swiper(this.sliderEl, {
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      });
    },
  }));
});