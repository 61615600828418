document.addEventListener('alpine:init', () => {
  Alpine.data('counter', () => ({
    _value: undefined,
    get value() {
      return this._value;
    },

    set value(value) {
      const matches = /^([^0-9]*)([0-9]+)([^0-9]*)$/.exec(value);

      if (matches) {
        this._value = value;
        this.options.prefix = matches[1];
        this.number = +matches[2];
        this.options.suffix = matches[3];
      }
    },

    number: undefined,

    init() {
      this.value = this.$root.dataset.value;
    },

    options: {
      useEasing: true,
      useGrouping: true,
      separator: '.',
      decimal: ',',
      prefix: '',
      suffix: '',
    },

    countUp() {
      if (this.number !== undefined) {
        const counter = new CountUp(this.$refs.counter, 0, this.number, 0, 2, this.options);

        if (!counter.error) {
          counter.start();
        }
      }
    },
  }));
});