document.addEventListener('alpine:init', () => {
  Alpine.data('header', () => ({
    _sidebarOpen: false,
    get sidebarOpen() {
      return this._sidebarOpen;
    },
    set sidebarOpen(value) {
      this._sidebarOpen = value;
      this.sidebarClosing = false;

      clearTimeout(this.sidebarClosingTimeout);

      if (value) {
        this.submenuOpen = false;
      }
    },

    submenuOpen: false,
    sidebarClosing: false,
    sidebarClosingTimeout: null,
    prevScrollY: 0,
    y: 0,
    compact: false,
    initialized: false,

    init() {
      this.$nextTick(() => {
        this.onScroll();
        this.initialized = true;
      });
    },

    closeSidebar() {
      this.sidebarClosing = true;

      this.sidebarClosingTimeout = setTimeout(() => {
        this.sidebarOpen = false;
      }, 500);
    },

    onScroll() {
      const scrollY = Math.max(0, window.scrollY);
      const dy = scrollY - this.prevScrollY;

      this.prevScrollY = scrollY;
      this.compact = this.prevScrollY > this.getMaxHeaderY();

      if (dy !== 0) {
        this.y -= dy;
        this.y = Math.min(0, Math.max(-this.getMaxHeaderY(), this.y));
      }
    },

    getMaxHeaderY() {
      return 56 + this.$root.offsetTop;
    },
  }));

  // ---

  Alpine.data('secondaryMenu', () => ({
    active: -1,

    init() {
      this.$nextTick(() => {
        this.$root.querySelectorAll('[data-secondary-menu-item]').forEach((item) => {
          item.click();
          item.click();
        });
      });
    },

    toggle(index, el) {
      const active = this.active === index ? -1 : index;

      if (index > -1) {
        const ul = el.nextElementSibling;
        const w = ul.offsetWidth;

        ul.style.transition = 'none';
        ul.style.left = null;
        ul.style.left = `${Math.min(0, window.innerWidth - ul.getBoundingClientRect().left - w - 24)}px`;
        ul.offsetWidth;
        ul.style.transition = null;
      }

      this.active = active;
    },
  }));
});