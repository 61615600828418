document.addEventListener('alpine:init', () => {
  Alpine.data('gallery', () => ({
    isOpen: false,
    isVisible: false,
    visibleTimeout: null,
    slider: null,

    init() {
      this.$nextTick(() => {
        this.slider = new Swiper(this.$refs.slider, {
          slidesPerView: 1,
          keyboard: {
            enabled: true,
          },
          navigation: {
            enabled: true,
            prevEl: '.prev button',
            nextEl: '.next button',
          },
        });
      });
    },

    open(i) {
      this.isOpen = true;
      this.slider.enable();
      this.slider.slideTo(i, 0);
      clearTimeout(this.visibleTimeout);
      setTimeout(() => {
        this.isVisible = true;
      }, 50);
    },

    close() {
      this.isVisible = false;
      this.visibleTimeout = setTimeout(() => {
        this.isOpen = false;
        this.slider.disable();
      }, 300);
    },
  }));
});