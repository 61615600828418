document.addEventListener('alpine:init', () => {
  Alpine.data('packages', () => ({
    expanded: {},

    popup: {
      active: false,
      visible: false,
      top: 0,
      left: 0,
    },

    openPopup(id, button) {
      const rect = button.getBoundingClientRect();

      this.popup.active = id;
      this.popup.top = rect.top + window.scrollY;
      this.popup.left = rect.left;

      setTimeout(() => {
        if (this.$refs.popup.offsetLeft + this.$refs.popup.offsetWidth > window.innerWidth) {
          this.popup.left = Math.max(0, window.innerWidth - this.$refs.popup.offsetWidth - 24);
          this.popup.top += 24;
        }

        setTimeout(() => {
          this.popup.visible = true;
        });
      });
    },

    closePopup() {
      this.popup.visible = false;

      setTimeout(() => {
        this.popup.active = false;
      }, 150);
    },
  }));
});