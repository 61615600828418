window.recaptchaLoaded = false;

document.addEventListener('alpine:init', () => {
  Alpine.data('hsForm', () => ({
    formError: null,
    fieldErrors: {},
    disabled: false,
    successMessage: '',

    init() {
      this.$nextTick(() => {
        const search = new URLSearchParams(window.location.search);

        for (const [name, value] of search.entries()) {
          if (name[0] === '_') {
            const input = this.$root.querySelector(`[name="${name.replace('_', '')}"]`);

            if (input) {
              input.value = value;
            }
          } else if (name.startsWith('utm_')) {
            const input = this.$root.querySelector(`[name="${name}"]`);

            if (input) {
              input.value = value;
            }
          }
        }

        if (GLOBALS.recaptchaSiteKey) {
          const recaptchaScript = document.createElement('script');
          recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${GLOBALS.recaptchaSiteKey}`;
          document.head.append(recaptchaScript);
          window.recaptchaLoaded = true;
        }
      });
    },

    async submit(e) {
      const formData = new FormData(e.target);
      const eventDetail = { detail: { hubspotFormId: this.$root.dataset.hsGuid } };

      if (window.recaptchaLoaded && window.grecaptcha) {
        await new Promise((resolve) => {
          grecaptcha.ready(function () {
            grecaptcha.execute(GLOBALS.recaptchaSiteKey, { action: 'submit' }).then(function (token) {
              formData.set('recaptchaToken', token);
              resolve();
            });
          });
        });
      }

      this.disabled = true;
      this.formError = null;
      this.fieldErrors = {};

      window.dispatchEvent(new CustomEvent('formSubmitAttempt', eventDetail));

      fetch(GLOBALS.ajaxUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then(({ data, success }) => {
          if (success) {
            window.dispatchEvent(new CustomEvent('formSubmitSuccess', eventDetail));

            if (data.message) {
              this.successMessage = data.message;
            } else if (data.redirect) {
              window.location = data.redirect;
            } else {
              this.successMessage =
                'Vielen Dank für Ihre Formularübermittlung. Ihre Anfrage wurde erfolgreich gesendet!';
            }
          } else {
            this.formError = data.errors?.form ?? null;
            this.fieldErrors = data.errors?.fields ?? {};
            this.disabled = false;
            window.dispatchEvent(new CustomEvent('formSubmitValidationError', eventDetail));
          }
        })
        .catch((e) => {
          console.error(e);
          this.disabled = false;
          window.dispatchEvent(new CustomEvent('formSubmitFail', eventDetail));
        })
        .finally(() => {
          setTimeout(() => {
            const target = this.$root.querySelector('.success, .form-error, .error');

            if (target?.classList.contains('success')) {
              target.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth' });
            } else {
              target?.parentElement.scrollIntoView({ behavior: 'smooth' });
            }
          });
        });
    },
  }));
});