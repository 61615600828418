document.addEventListener('alpine:init', () => {
  Alpine.data('glossaryListing', () => ({
    allLetters: [],
    allPosts: [],
    activeLetter: '',
    keywords: '',
    showLetter: {},
    showPost: {},
    results: 0,

    init() {
      this.$nextTick(() => {
        const letterElements = [...this.$root.querySelectorAll('[href^="#"]')];
        this.allLetters = letterElements.map((el) => el.href.slice(1).toLowerCase());

        console.log(this.allLetters);

        const postElements = [...this.$root.querySelectorAll('.post')];
        this.allPosts = postElements.map((el) => ({
          id: +el.dataset.id,
          text: el.textContent.replace(/\s\s*/g, ' ').trim().toLowerCase(),
          letter: el.dataset.letter,
        }));

        this.refresh();
      });
    },

    refresh() {
      const keywords = this.keywords
        .toLowerCase()
        .split(' ')
        .map((k) => k.trim())
        .filter(Boolean);

      this.showLetter = {};
      this.showPost = Object.fromEntries(this.allPosts.map(({ id }) => [id, true]));

      for (const k of keywords) {
        for (const post of this.allPosts) {
          if (!post.text.includes(k)) {
            this.showPost[post.id] = false;
          }
        }
      }

      for (const post of this.allPosts) {
        if (this.showPost[post.id]) {
          this.showLetter[post.letter] = true;
        }
      }

      this.activeLetter = Object.keys(this.showLetter)[0];
      this.results = Object.keys(this.showLetter).length;
    },
  }));
});