document.addEventListener('alpine:init', () => {
  Alpine.data('partners', () => ({
    active: false,
    active2: false,
    id: null,

    open(id) {
      this.active = true;
      setTimeout(() => {
        this.id = id;
        this.active2 = true;
      });
    },

    close() {
      this.active2 = false;

      setTimeout(() => {
        this.id = null;
        this.active = false;
      }, 300);
    },
  }));
});