import './main-modules/utils/dom.js';
import './main-modules/layout/partials/header.js';
import './main-modules/layout/partials/footer.js';
import './main-modules/layout/blocks/card-slider.js';
import './main-modules/layout/blocks/testimonials.js';
import './main-modules/layout/blocks/form.js';
import './main-modules/layout/blocks/packages.js';
import './main-modules/layout/blocks/count-up.js';
import './main-modules/layout/blocks/share.js';
import './main-modules/layout/blocks/partners.js';
import './main-modules/layout/blocks/anchor-buttons.js';
import './main-modules/layout/blocks/toggle.js';
import './main-modules/layout/blocks/speakers.js';
import './main-modules/layout/partials/article-floater.js';
import './main-modules/layout/partials/webinar-floater.js';
import './main-modules/layout/partials/whitepaper-floater.js';
import './main-modules/layout/blocks/recruiting-card.js';
import './main-modules/layout/blocks/expandable-box.js';
import './main-modules/layout/templates/template-glossary.js';
import './main-modules/layout/blocks/video.js';
import './main-modules/layout/blocks/gallery.js';
import './main-modules/layout/blocks/grid-gallery.js';
import './main-modules/layout/blocks/icon-box.js';
import './main-modules/layout/blocks/step.js';
import './main-modules/layout/blocks/vertical-tabs.js';
import './main-modules/layout/templates/template-floater.js';
import './main-modules/layout/blocks/support.js';
import './main-modules/layout/blocks/jobs.js';
import './main-modules/layout/partials/job-floater.js';
import './main-modules/layout/blocks/hs-form.js';
import './main-modules/layout/blocks/section-news.js';
import './main-modules/layout/partials/blog-card.js';
import './main-modules/layout/blocks/video-slider.js';