document.addEventListener('alpine:init', () => {
  Alpine.data('verticalTabs', () => ({
    tabs: [],
    _activeTab: null,
    get activeTab() {
      return this._activeTab;
    },
    set activeTab(value) {
      this._activeTab = value;

      if (value && window.innerWidth < 1024) {
        setTimeout(() => {
          this.$root.querySelector(`[data-tab-label="${value}"]`)?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },

    init() {
      this.$nextTick(() => {
        for (const tab of [...this.$refs.content.querySelectorAll('[data-block="tab"]')]) {
          this.tabs.push({
            label: tab.dataset.label,
            html: tab.outerHTML,
          });

          if (!this.activeTab) {
            this.activeTab = tab.dataset.label;
          }
        }
      });
    },
  }));
});