document.addEventListener('alpine:init', () => {
  Alpine.data('share', () => ({
    share(medium) {
      const url = this.$root.dataset.url;
      const title = this.$root.dataset.title;

      switch (medium) {
        case 'facebook':
          window.open(`http://www.facebook.com/sharer.php?u=${url}`);
          break;
        case 'linkedin':
          window.open(`https://www.linkedin.com/shareArticle?mini=true&url={url}&title=${title}`);
          break;
        case 'xing':
          window.open(`https://www.xing.com/spi/shares/new?url=${url}`);
          break;
      }
    },
  }));
});