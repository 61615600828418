document.addEventListener('alpine:init', () => {
  Alpine.data('support', () => ({
    active: null,
    fieldErrors: {},

    init() {
      this.active = this.$root.querySelector('[data-title]').textContent;
    },

    onChange(event) {
      this.active = event.target.value;
    },
  }));
});