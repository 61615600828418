document.addEventListener('alpine:init', () => {
  Alpine.data('recruitingCard', () => ({
    index: 0,

    init() {
      const i = [...this.$root.parentElement.parentElement.children].indexOf(this.$root.parentElement);
      this.index = (i + 1).toString();

      if (this.index.length === 1) {
        this.index = 0 + this.index;
      }
    },
  }));
});