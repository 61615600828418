document.addEventListener('alpine:init', () => {
  Alpine.data('webinarFloater', () => ({
    stacked: false,
    stackContainer: document.querySelector('.blocks .prose[x-ref="innerBlocks"'),
    originalContainer: null,

    init() {
      if (this.stackContainer) {
        this.originalContainer = this.$root.parentElement;
        this.update = this.update.bind(this);
        this.update();
        window.addEventListener('resize', this.update);
      }
    },

    update() {
      if (window.innerWidth < 1439) {
        if (!this.stacked) {
          this.stackContainer.append(this.$root);
          this.stacked = true;
        }
      } else {
        if (this.stacked) {
          this.originalContainer.append(this.$root);
          this.stacked = false;
        }
      }
    },
  }));
});