document.addEventListener('alpine:init', () => {
  Alpine.data('footer', () => ({
    initialized: false,
    open: {},
    resizeCounter: 1,
    groupItems: [],
    timeout: null,

    init() {
      this.$nextTick(() => this.ready());
    },

    ready() {
      this.groupItems = this.$root.querySelectorAll('[x-bind="groupItems"]');

      if (document.readyState == 'complete') {
        this.initialized = true;
      } else {
        window.addEventListener('load', () => {
          this.initialized = true;
        });
      }

      window.addEventListener('resize', () => {
        this.groupItems.forEach((groupItem) => {
          groupItem.style.height = 0;
          groupItem.style.transition = 'none';
        });

        this.resizeCounter++;

        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.groupItems.forEach((groupItem) => {
            groupItem.style.transition = null;
          });

          this.timeout = null;
        }, 400);
      });
    },

    icon: {
      ':class'() {
        return {
          'after:scale-y-100': !this.open[+this.$el.dataset.index],
          'after:scale-y-0': this.open[+this.$el.dataset.index],
        };
      },
    },

    groupItems: {
      ':style'() {
        return {
          height:
            this.initialized && this.resizeCounter && this.open[+this.$el.dataset.index]
              ? `${this.$el.scrollHeight + 16}px`
              : 0,
        };
      },
    },
  }));

  Alpine.data('newsletterForm', () => ({
    disabled: false,
    formError: null,
    fieldErrors: {},
    successMessage: '',
    dirty: false,

    init() {
      if (GLOBALS.recaptchaSiteKey) {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${GLOBALS.recaptchaSiteKey}`;
        document.head.append(recaptchaScript);
        window.recaptchaLoaded = true;
      }
    },

    async subscribe(e) {
      const formData = new FormData(e.target);

      if (window.recaptchaLoaded && window.grecaptcha) {
        await new Promise((resolve) => {
          grecaptcha.ready(function () {
            grecaptcha.execute(GLOBALS.recaptchaSiteKey, { action: 'submit' }).then(function (token) {
              formData.set('recaptchaToken', token);
              resolve();
            });
          });
        });
      }

      this.disabled = true;
      this.formError = null;
      this.fieldErrors = {};

      fetch(GLOBALS.ajaxUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then(({ data, success }) => {
          if (success) {
            if (data.message) {
              this.successMessage = data.message;
            } else if (data.redirect) {
              window.location = data.redirect;
            } else {
              this.successMessage =
                'Vielen Dank für Ihre Formularübermittlung. Ihre Anfrage wurde erfolgreich gesendet!';
            }
          } else {
            this.formError = data.errors?.form ?? null;
            this.fieldErrors = data.errors?.fields ?? {};
            this.disabled = false;
          }
        })
        .catch((e) => {
          console.error(e);
          this.disabled = false;
        });
    },
  }));
});