export function isBlock(el, name) {
  return el?.dataset.blockName === name;
}

export function isAdmin() {
  return document.body.classList.contains('wp-admin');
}

export function getTopLevelBlocks() {
  return [...document.querySelectorAll('[data-block-top]')];
}

export function getTopLevelBlock(el) {
  let block = el;

  while (block) {
    if (isTopLevelBlock(block)) {
      return block;
    }

    block = block.parentElement;
  }

  return null;
}

export function isTopLevelBlock(block) {
  return block?.hasAttribute('data-block-top');
}

export function getInnerBlocks(block) {
  const container =
    block?.getAttribute('x-ref') === 'innerBlocks' ? block : block?.querySelector('[x-ref="innerBlocks"]');

  if (container?.children[0]?.classList.contains('block-editor-inner-blocks')) {
    return [...container.children[0].children[0].children];
  } else if (container) {
    return [...container.children];
  }

  return [];
}

export function getInnerBlockIndex(block) {
  const parent = getParentBlock(block);
  const innerBlocks = getInnerBlocks(parent);
  return innerBlocks?.indexOf(block) ?? -1;
}

export function getParentBlock(el, name = null) {
  let parent = el?.parentElement;

  while (parent) {
    if (name ? isBlock(parent, name) : parent.hasAttribute('data-block-name')) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return null;
}

export function getEditorBlockWrapper(block) {
  let parent = block?.parentElement;

  while (parent) {
    if (parent.hasAttribute('data-block')) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return null;
}

export function getPrevTopLevelBlock(el) {
  if (isAdmin()) {
    const topBlock = getTopLevelBlock(el);
    const wrapper = getEditorBlockWrapper(topBlock);
    return wrapper?.previousElementSibling?.querySelector('[data-block-name]') ?? null;
  }

  return getTopLevelBlock(el)?.previousElementSibling ?? null;
}

export function getNextTopLevelBlock(el) {
  if (isAdmin()) {
    const topBlock = getTopLevelBlock(el);
    const wrapper = getEditorBlockWrapper(topBlock);
    return wrapper?.nextElementSibling?.querySelector('[data-block-name]') ?? null;
  }

  return getTopLevelBlock(el)?.nextElementSibling ?? null;
}

export function getNthInnerBlockInPrevTopLevelBlock(el, index) {
  const prev = getPrevTopLevelBlock(el);
  const innerBlocks = getInnerBlocks(prev);

  if (innerBlocks[index]?.hasAttribute('data-block')) {
    return innerBlocks[index].querySelector('[data-block-name]') ?? null;
  }

  return innerBlocks[index] ?? null;
}

export function getNthInnerBlockInNextTopLevelBlock(el, index) {
  const next = getNextTopLevelBlock(el);
  const innerBlocks = getInnerBlocks(next);

  if (innerBlocks[index]?.hasAttribute('data-block')) {
    return innerBlocks[index].querySelector('[data-block-name]') ?? null;
  }

  return innerBlocks[index] ?? null;
}

export function addInnerBlockDelays(block, interval = 150, min = 0, max = Infinity) {
  const innerBlocks = getInnerBlocks(block);

  innerBlocks
    ?.filter((innerBlock) => !innerBlock.classList.contains('wp-block-spacer'))
    .forEach((innerBlock, index) => {
      const value = Math.max(min, Math.min(max, index * interval));
      innerBlock.style.transitionDelay = `${value}ms`;
    });
}

export function addInnerBlockTranslates(block, interval = 48, min = 0, max = Infinity) {
  const innerBlocks = getInnerBlocks(block);

  innerBlocks
    ?.filter((innerBlock) => !innerBlock.classList.contains('wp-block-spacer'))
    .forEach((innerBlock, index) => {
      const value = Math.max(min, Math.min(max, index * interval));
      innerBlock.style.transform = `translate3d(0, ${value}px, 0)`;
    });
}

let _body = null;

export function body() {
  if (!_body) {
    _body = isAdmin() ? document.querySelector('.is-root-container') : document.body;
  }

  return _body;
}