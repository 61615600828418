document.addEventListener('alpine:init', () => {
  Alpine.data('videoSlider', () => ({
    isOpen: false,
    isVisible: false,
    visibleTimeout: null,
    slider: null,
    activeVideo: null,
    activeVideoVimeo: null,

    init() {
      this.$nextTick(() => {
        this.slider = new Swiper(this.$refs.slider, {
          slidesPerView: 1,
          keyboard: {
            enabled: true,
          },
          navigation: {
            enabled: true,
            prevEl: '.prev button',
            nextEl: '.next button',
          },
          on: {
            slideChange: () => this.play(),
          },
        });
      });
    },

    open(i) {
      this.isOpen = true;
      this.slider.enable();
      this.slider.slideTo(i, 0);
      clearTimeout(this.visibleTimeout);
      setTimeout(() => {
        this.isVisible = true;
        this.play();
      }, 50);
    },

    close() {
      this.isVisible = false;
      this.visibleTimeout = setTimeout(() => {
        this.isOpen = false;
        this.slider.disable();
        this.activeVideo?.pause();

        if (this.activeVideoVimeo) {
          const vimeo = this.activeVideoVimeo;
          setTimeout(() => {
            if (vimeo !== this.activeVideoVimeo) {
              vimeo.innerHTML = '';
            }
          }, 400);
        }

        this.activeVideo = null;
        this.activeVideoVimeo = null;
      }, 300);
    },

    async play() {
      const activeSlide = this.slider.slides[this.slider.activeIndex];

      this.activeVideo?.pause();

      if (this.activeVideoVimeo) {
        const vimeo = this.activeVideoVimeo;
        setTimeout(() => {
          if (vimeo !== this.activeVideoVimeo) {
            vimeo.innerHTML = '';
          }
        }, 400);
      }

      this.activeVideo = activeSlide.querySelector('[data-video]');
      this.activeVideoVimeo = activeSlide.querySelector('[data-vimeo]');

      if (this.activeVideoVimeo) {
        const match = this.activeVideoVimeo.dataset.vimeo.match(/([0-9]+)(?:\/([0-9a-z]+)|$)?/i);

        if (match) {
          await new Promise((resolve) => {
            this.activeVideoVimeo.addEventListener('next', () => resolve(), { once: true });
            this.activeVideoVimeo.innerHTML = `<iframe @load="$dispatch('next')" src="https://player.vimeo.com/video/${
              match[1]
            }?${
              match[2] ? `h=${match[2]}&` : ''
            }&autoplay=1&title=0&byline=0&portrait=0" class="video-landscape:w-auto w-full video-landscape:h-full h-auto aspect-video m-auto rounded pointer-events-auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`;
          });
        }
      } else if (this.activeVideo) {
        this.activeVideo.play();
      }
    },
  }));
});