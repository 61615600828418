document.addEventListener('alpine:init', () => {
  Alpine.data('cardSlider', () => ({
    sliderEl: document.createElement('div'),
    dragIndicator: document.createElement('div'),

    init() {
      if (!window.wp) {
        this.sliderEl.classList.add(
          'swiper-container',
          'w-full',
          'pl-[Max(3rem,calc((100%-83rem)/2+3rem))]',
          'ph:pl-[Max(1.5rem,calc((100%-83rem)/2+1.5rem))]',
          'pr-6',
          'ph:pr-4',
          'overflow-hidden'
        );

        const children = [...this.$root.children];
        const wrapper = document.createElement('div');
        wrapper.classList.add('swiper-wrapper');

        children.forEach((child) => {
          const slide = document.createElement('div');
          slide.classList.add('swiper-slide', 'w-auto');
          slide.append(child);
          wrapper.append(slide);
        });

        this.dragIndicator.classList.add(
          'absolute',
          'z-over',
          'top-1/2',
          'right-[Max(3rem,calc((100%-83rem)/2+3rem))]',
          'flex',
          'tl:hidden',
          'justify-center',
          'items-center',
          'w-22',
          'h-22',
          'bg-midnight',
          'font-semibold',
          'text-16',
          'text-white',
          'uppercase',
          'rounded-full',
          'translate-x-14',
          '-translate-y-1/2',
          'transition-all'
        );

        if (this.$root.dataset.dragText) {
          this.dragIndicator.textContent = this.$root.dataset.dragText;
        } else {
          const dragIcon = document.createElement('i');
          dragIcon.classList.add('ph', 'ph-hand-swipe-left', 'text-32');
          this.dragIndicator.append(dragIcon);
        }

        this.sliderEl.append(wrapper);
        this.$root.append(this.sliderEl);

        setTimeout(() => {
          if ([...wrapper.children].reduce((sum, slide) => sum + slide.offsetWidth, 0) > wrapper.offsetWidth) {
            this.$root.append(this.dragIndicator);
          }
        });

        this.initSwiper();
      }
    },

    initSwiper() {
      new Swiper(this.sliderEl, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        breakpoints: {
          768: {
            spaceBetween: 24,
          },
        },
        on: {
          sliderFirstMove: () => {
            this.dragIndicator.classList.add('invisible', 'opacity-0');
          },
        },
      });
    },
  }));
});