document.addEventListener('alpine:init', () => {
  Alpine.data('blogCard', () => ({
    init() {
      this.$nextTick(() => {
        this.$root.querySelectorAll('[data-tooltip]').forEach((el) => {
          tippy(el, {
            content: el.dataset.tooltip,
          });
        });
      });
    },
  }));
});